@import url("https://fonts.googleapis.com/css2?family=Modak&display=swap");

body {
  margin: 0;
  font-family: "Modak", system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modak-regular {
  font-family: "Modak", system-ui;
  font-weight: 400;
  font-style: normal;
}

h1 {
  font-size: min(10rem, 10vw);
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-shadow: 0 0 10px #ff00de, 0 0 20px #ff00de, 0 0 40px #ff00de,
    0 0 80px #ff00de, 0 0 160px #ff00de;
  -webkit-text-stroke: 0.2rem #ff00de;
}
