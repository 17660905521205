.audio-player {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #000;
  padding: 40px 20px;
  border-radius: 100px;
  gap: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 1);
  border: 10px solid #ff00de;
  width: min(400px, calc(100vw - 90px));
}

.audio-player h3 {
  -webkit-text-stroke: 0.1rem #ff00de;
  font-size: 3rem;
  margin: 0px;
  padding: 0px;
  line-height: 80%;
}

.audio-player button {
  background-color: #5bcffb;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 2rem;
}

.audio-player button:hover {
  background-color: #f6aab9;
}

.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
}

.audio-player button:active {
  background-color: #03dac5;
}
.bubble-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 300px;
  height: 20px;
  border-radius: 10px;
  background: linear-gradient(90deg, #f6aab9, #ffffff, #5bcffb);
  outline: none;
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
}

.bubble-slider:hover {
  opacity: 1;
}

.bubble-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 40px;
  background: radial-gradient(circle, #f72585, #ffb6c1, #ffffff);
  border-radius: 50%;
  border: 3px solid #ffffff;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(255, 182, 193, 0.8);
  transition: transform 0.2s ease;
}

.bubble-slider::-webkit-slider-thumb:hover {
  transform: scale(1.2);
}

.bubble-slider::-moz-range-thumb {
  width: 40px;
  height: 40px;
  background: radial-gradient(circle, #f72585, #ffb6c1, #ffffff);
  border-radius: 50%;
  border: 3px solid #ffffff;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(255, 182, 193, 0.8);
  transition: transform 0.2s ease;
}

.bubble-slider::-moz-range-thumb:hover {
  transform: scale(1.2);
}
